import React, { useState, useRef, useEffect } from "react";
import "./solutions.css";

import { Box, Grid, Typography, Container, Button } from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { KeyboardArrowRight } from "@mui/icons-material";


interface SolutionsType {
  handleClick: (section: string) => void;
}

const SolutionComponent: React.FC<SolutionsType> = ({ handleClick }) => {
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const scrollableColumnRef = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const items = [
    {
      id: 1,
      key: "qPilot",
      title: "qPilot - Precision-Powered Business Guidance",
      content:
        "Harness the full potential of generative AI with qPilot, the ultimate navigator for the modern enterprise. Through advanced data analysis and actionable insights, qPilot streamlines decision-making processes, fostering a data-driven approach that underpins strategic growth and innovation.",
      children: [
        "AI-Powered. Human-Guided Predictive Analytics",
        "Data Science. Real World Results",
        "Predictive Market Insights",
        "Strategic Decision Support",
        "Complex Data Simplification",
      ],
      image: "/static/img/landingPage/qpilot.gif",
    },
    {
      id: 2,
      key: "qFlex",
      title:
        "qFlex - Unleashing Consumption Economics",
      content:
        "In today’s diverse business ecosystem, companies big and small seek to harness the power of Consumption Economics to drive growth and innovation. qFlex democratizes access to advanced AI-driven tools for mastering consumption-based strategies:",
      children: [
        "Revolutionized Revenue Models",
        "Consumption & Cost Insights",
        "Predictive Financial Foresight",
        "Customer-Centric Value Realization",
        "Dynamic Service and Scaling",
        "Agile Market Adaptation",
      ],
      image: "/static/img/landingPage/qflex.gif",
    },
    {
      id: 3,
      key: "qSMaRt",
      title:
        "qSMaRt - Subscription Dynamics with qSMaRt: Your All-in-One Platform",
      content:
        "Transform your subscription landscape with qSMaRt, the quintessential engine driving subscription excellence. qSMaRt streamlines the entire subscriber experience, deploying comprehensive automation that navigates your customers from initial sign-up through every stage of their journey, including seamless transactions, renewals, and beyond. This sophisticated platform not only simplifies but amplifies the subscription process, offering:",
      children: [
        "Automated Subscription Workflows",
        "Flexible Plans and Pricing",
        "Trial Management",
        "Effortless Billing",
        "Self-Service Portal",
        "Holistic Integrations",
        "Automated Renewal Management",
        "Predictive Analytics for Subscriptions",
      ],
      image: "/static/img/landingPage/qsmart.gif",
    },
    {
      id: 4,
      key: "qUbe",
      title:
        "qUbe - Experience the pinnacle of smart operations with qUbe, qWorks.ai's central platform",
      content:
        "A comprehensive platform that unifies various business applications, harnessing the power of AI to provide an integrated, intelligent system for optimized business processes and decision-making.",
      children: [
        "Centralized Integration",
        "Intuitive Design",
        "Data Synergy",
        "Scalable Architecture",
        "Security and Compliance",
      ],
      image: "/static/img/landingPage/qube.gif",
    },
    // Add more items as needed
  ];

  useEffect(() => {
    const handleScroll = () => {
      //   const scrollableColumn = scrollableColumnRef.current;
      var scrollableColumn = document.getElementById("root");
      if (!scrollableColumn) return;
      const containerRect = scrollableColumn.getBoundingClientRect();
      const scrollableRect = {
        top: containerRect.top + window.pageYOffset,
        bottom: containerRect.bottom + window.pageYOffset,
      };
      const itemsInView = items.filter((item) => {
        const itemElement = document.getElementById(`item-${item.key}`);
        if (!itemElement) return false;
        const itemRect = itemElement.getBoundingClientRect();
        return (
          itemRect.top >= (scrollableRect.top - 280) &&
          itemRect.bottom <= scrollableRect.bottom + 280
        );
      });
      if (itemsInView.length > 0) {
        setSelectedItem(itemsInView[Math.floor(itemsInView.length / 2)].id);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [items]);

  const handleItemClick = (id: number) => {
    setSelectedItem(id);
  };
  const Wrapper = styled.div`
    ${spacing};
    position: relative;
    background: #f6f9fc;
  `;
  const Image = styled.img`
    max-width: 100%;
    height: auto;
    display: block;
    position: relative;
    image-rendering: optimizequality;
    image-rendering: -webkit-optimize-contrast;
    ${(props) => props.theme.breakpoints.up("md")} {
    }
  `;

  const handleButtonClick = (section: any) => {
    handleClick(section);
  };

  return (
    <Wrapper py={18}>
      <Container>
        <Grid container className="container">
          <Grid
            item
            xs={12}
            md={5}
            className="scrollable-column"
            ref={scrollableColumnRef}
          >
            {items.map((item) => (
              <div
                key={item.id}
                id={`item-${item.key}`}
                className={selectedItem === item.id ? "item selected" : "item"}
                onClick={() => handleItemClick(item.id)}
              >
                <Typography variant="h4">{item.title}</Typography>
                <Typography variant="body1" mt={6} color="textSecondary">
                  {item.content}
                </Typography>

                {item.children.map((e) => (
                  <Box sx={{ display: "flex", alignItems: "center" }} mt={4}>
                    <Box
                      sx={{
                        width: "1px",
                        height: "24px",
                        backgroundColor: "#635BFF",
                      }}
                    ></Box>
                    <Typography variant="body1" ml={4}>
                      {e}
                    </Typography>
                  </Box>
                ))}
                <Box mt={4}>
                  <Button variant="contained" color="primary" size="large" onClick={() => handleButtonClick("CONTACTUS")}>
                    Book a Demo <KeyboardArrowRight></KeyboardArrowRight>
                  </Button>
                  {/* <Button
                    sx={{
                      marginLeft: "16px",
                    }}
                    variant="outlined"
                    color="primary"
                    size="large"
                  >
                    Book a Demo
                  </Button> */}
                </Box>
                <Box display={{ xs: "block", md: "none" }} mt={6}>
                  <Image src={item.image}></Image>
                </Box>
              </div>
            ))}
          </Grid>
          <Grid
            item
            xs={0}
            md={7}
            className="sticky-column"
            display={{ xs: "none", md: "block" }}
          >
            <div className="sticky-content">
              <Image
                src={
                  items.find((item) => item.id === selectedItem)?.image ||
                  items[0].image
                }
              ></Image>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
}

export default SolutionComponent;
