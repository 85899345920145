import { SidebarItemsType } from "../../types/sidebar";

import StarBorderIcon from "@mui/icons-material/StarBorder";
const pagesSection = [
  // {
  //   href: "About",
  //   icon: "",
  //   title: "About us",
  // },
  {
    href: "Products",
    icon: "",
    title: "Products",
    badge: "",
  },
  {
    href: "Solutions",
    icon: "",
    title: "Solutions",
    children: [
      {
        href: "qPilot",
        title: "qPilot",
        icon: StarBorderIcon,
      },
      {
        href: "qFlex",
        title: "qFlex",
        icon: StarBorderIcon,
      },
      {
        href: "qSMaRt",
        title: "qSMaRt",
        icon: StarBorderIcon,
      },
      {
        href: "qUbe",
        title: "qUbe",
        icon: StarBorderIcon,
      }
    ],
  },
  // {
  //   href: "Blog",
  //   icon: "",
  //   title: "Blog",
  //   badge: "",
  // },
  // {
  //   href: "Support",
  //   icon: "",
  //   title: "Support",
  //   badge: "",
  //   children: [],
  // },
  // {
  //   href: "Pricing",
  //   icon: "",
  //   title: "Pricing",
  //   badge: "",
  // },
] as SidebarItemsType[];

const navItems = [
  {
    title: "Pages",
    pages: pagesSection,
  },
];

export default navItems;
