import React from "react";
import styled from "@emotion/styled";

import { Grid, Button as MuiButton } from "@mui/material";
import { KeyboardArrowRight } from "@mui/icons-material";
import { spacing, SpacingProps } from "@mui/system";
const Footer = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-top: 1px solid #e0e0e0;
`;
interface ButtonProps extends SpacingProps {
  component?: React.ElementType;
  to?: string;
  target?: string;
}
const Button = styled(MuiButton)<ButtonProps>(spacing);

const SidebarFooter: React.FC = ({ ...rest }) => {
  return (
    <Footer {...rest}>
      <Grid container spacing={2} justifyContent="center" alignItems={"center"}>
        <Grid item>
          <Button ml={2} color="primary" variant="contained">
            Book a demo <KeyboardArrowRight></KeyboardArrowRight>
          </Button>
        </Grid>
      </Grid>
    </Footer>
  );
};

export default SidebarFooter;
