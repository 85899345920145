import React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import {
  Button,
  Container,
  Grid,
  Typography as MuiTypography,
  TextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ThemeProps } from "../../../types/theme";

const Typography = styled(MuiTypography)(spacing);

const JoinUs = ({ theme }: ThemeProps) => {
  const Wrapper = styled.div`
    ${spacing};
    position: relative;
    background: #efefff;
  `;

  return (
    <Wrapper pt={12} pb={12}>
      <Container>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={12} md={6} lg={6} xl={6}>
            <Typography variant="h4" gutterBottom>
              Join our newsletter
            </Typography>
            <Typography variant="body1" gutterBottom>
              We’ll send you a nice letter once per week. No spam.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              textAlign: "right", // Default alignment
              [theme.breakpoints.down("md")]: {
                // Apply for md and larger screens
                textAlign: "center",
              },
            }}
          >
            <TextField
              label="Your email address"
              variant="standard"
              sx={{
                [theme.breakpoints.down("md")]: {
                  // Apply for md and larger screens
                  width: "100%",
                },
              }}
            />
            <Button
              sx={{
                marginLeft: "16px",
                marginTop: "16px",
              }}
              variant="contained"
              color="primary"
              size="large"
            >
              Subscribe
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default withTheme(JoinUs);
