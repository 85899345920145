import React from "react";

import async from "./components/Async";

import PresentationLayout from "./layouts/Presentation";

// Landing
import Landing from "./pages/presentation/Landing";

const routes = [
  {
    path: "/",
    element: <PresentationLayout />,
    children: [
      {
        path: "",
        element: <Landing />,
      },
    ],
  },
];

export default routes;
