import React from "react";
import styled from "@emotion/styled";

import {
  Grid,
  Container,
  Typography,
  IconButton,
} from "@mui/material";
// import { ReactComponent as Logo2 } from "../vendor/Logo2.svg";
import { ReactComponent as Facebook } from "../vendor/facebook.svg";
import { ReactComponent as Media } from "../vendor/media.svg";
import { ReactComponent as Linkedin } from "../vendor/linkedin.svg";
import { ReactComponent as Github } from "../vendor/github.svg";

const Wrapper = styled.div`
  max-width: ${(props) => props.theme.breakpoints.values.lg + "px"};
  width: 100%;
  padding: 0 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 0;
  }
`;

// const BrandIcon2 = styled(Logo2)`
//   margin-right: ${(props) => props.theme.spacing(2)};
//   margin-top: -2px;
//   color: ${(props) => props.theme.sidebar.header.brand.color};
//   fill: ${(props) => props.theme.sidebar.header.brand.color};

//   vertical-align: middle;
//   display: inline;
// `;

function Footer() {
  return (
    <Container sx={{ padding: "64px 0 !important" }}>
      <Grid container alignItems="center" justifyContent={"space-between"}>
        <Wrapper style={{ display: "block" }}>
          {/* <Grid
            container
            rowSpacing={8}
            columnSpacing={16}
            justifyContent="space-around"
          >
            <Grid item xs={12} md={4} lg={4}>
              <BrandIcon2 />
              <Box mt={6}>
                <Typography variant="body1" gutterBottom>
                  Design amazing digital experiences that create more happy in
                  the world.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={2} lg={2}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                gutterBottom
              >
                Products
              </Typography>
              <Typography variant="subtitle1" gutterBottom mt={4}>
                <Link href="#" color="InfoText">
                  qWorks.ai
                </Link>
              </Typography>
              <Typography variant="subtitle1" gutterBottom mt={4}>
                <Link href="#" color="InfoText">
                  qInsights
                </Link>
              </Typography>
              <Typography variant="subtitle1" gutterBottom mt={4}>
                <Link href="#" color="InfoText">
                  qPilot
                </Link>
              </Typography>
              <Typography variant="subtitle1" gutterBottom mt={4}>
                <Link href="#" color="InfoText">
                  qPrime
                </Link>
              </Typography>
              <Typography variant="subtitle1" gutterBottom mt={4}>
                <Link href="#" color="InfoText">
                  qConfigurate
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={6} md={2} lg={2}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                gutterBottom
              >
                Solutions
              </Typography>
              <Typography variant="subtitle1" gutterBottom mt={4}>
                <Link href="#" color="InfoText">
                  qUbe
                </Link>
              </Typography>
              <Typography variant="subtitle1" gutterBottom mt={4}>
                <Link href="#" color="InfoText">
                  qPilot
                </Link>
              </Typography>
              <Typography variant="subtitle1" gutterBottom mt={4}>
                <Link href="#" color="InfoText">
                  qSmart
                </Link>
              </Typography>
              <Typography variant="subtitle1" gutterBottom mt={4}>
                <Link href="#" color="InfoText">
                  qFlex
                </Link>
              </Typography>
              <Typography variant="subtitle1" gutterBottom mt={4}>
                qReceipe
                <Link href="#" color="InfoText">
                  qReceipe
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={6} md={2} lg={2}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                gutterBottom
              >
                Resources
              </Typography>
              <Typography variant="subtitle1" gutterBottom mt={4}>
                <Link href="#" color="InfoText">
                  Blog
                </Link>
              </Typography>
              <Typography variant="subtitle1" gutterBottom mt={4}>
                <Link href="#" color="InfoText">
                  Customer Stories
                </Link>
              </Typography>

              <Typography variant="subtitle1" gutterBottom mt={4}>
                <Link href="#" color="InfoText">
                  Cookie Settings
                </Link>
              </Typography>
              <Typography variant="subtitle1" gutterBottom mt={4}>
                <Link href="#" color="InfoText">
                  Privacy & Terms
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={6} md={2} lg={2}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                gutterBottom
              >
                Supports
              </Typography>
              <Typography variant="subtitle1" gutterBottom mt={4}>
                <Link href="#" color="InfoText">
                  Privacy & Terms
                </Link>
              </Typography>
              <Typography variant="subtitle1" gutterBottom mt={4}>
                <Link href="#" color="InfoText">
                  FAQ
                </Link>
              </Typography>
              <Typography variant="subtitle1" gutterBottom mt={4}>
                <Link href="#" color="InfoText">
                  Security
                </Link>
              </Typography>
              <Typography variant="subtitle1" gutterBottom mt={4}>
                <Link href="#" color="InfoText">
                  Careers
                </Link>
              </Typography>
              <Typography variant="subtitle1" gutterBottom mt={4}>
                <Link href="#" color="InfoText">
                  Contact us
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ marginTop: "64px", marginBottom: "32px" }} /> */}
          <Grid
            container
            justifyContent="space-between"
            alignItems={"center"}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <Grid item xs={12} md={6} lg={6}>
              <Typography variant="body1" gutterBottom mt={4}>
                © 2024 - qWorks.ai. All rights reserved.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={6} textAlign="right">
              {/* <IconButton aria-label="Facebook">
                <Facebook></Facebook>
              </IconButton>
              <IconButton aria-label="Media">
                <Media></Media>
              </IconButton> */}
              <IconButton aria-label="Linkedin" href="https://www.linkedin.com/company/qworks-ai/about/" target="_blank" rel="noopener noreferrer">
                <Linkedin></Linkedin>
              </IconButton>
              {/* <IconButton aria-label="Github">
                <Github></Github>
              </IconButton> */}
            </Grid>
          </Grid>
          <Grid
            container
            alignItems={"center"}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            <Grid item xs={12} md={6} lg={6}>
              <IconButton aria-label="Facebook">
                <Facebook></Facebook>
              </IconButton>
              <IconButton aria-label="Media">
                <Media></Media>
              </IconButton>
              <IconButton aria-label="Linkedin">
                <Linkedin></Linkedin>
              </IconButton>
              <IconButton aria-label="Github">
                <Github></Github>
              </IconButton>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Typography variant="body1" gutterBottom mt={4}>
                © 2024 - qWorks.ai. All rights reserved.
              </Typography>
            </Grid>
          </Grid>
        </Wrapper>
      </Grid>
    </Container>
  );
}

export default Footer;
