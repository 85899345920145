import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { spacing, SpacingProps } from "@mui/system";
import { useLocation } from "react-router-dom";

import {
  AppBar,
  Button as MuiButton,
  Container,
  Grid,
  Box,
  Toolbar,
  IconButton,
  PropTypes,
  Typography,
} from "@mui/material";
import { KeyboardArrowRight, Menu as MenuIcon } from "@mui/icons-material";
import { ReactComponent as Logo } from "../../../vendor/logo.svg";
import { ReactComponent as Logo2 } from "../../../vendor/Logo2.svg";
import dashboardItems from "../../../components/sidebar/dashboardItems";
import "./AppBar.css";
import Sidebar from "../../../components/sidebar/Sidebar";
// import { useTheme } from "@mui/material/styles";

interface ButtonProps extends SpacingProps {
  component?: React.ElementType;
  to?: string;
  target?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

const Brand = styled.div`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-family: ${(props) => props.theme.typography.fontFamily};
`;

const BrandIcon = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(2)};
  margin-top: -2px;
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 32px;
  height: 32px;

  vertical-align: middle;
  display: inline;
`;

const BrandIcon2 = styled(Logo2)`
  margin-right: ${(props) => props.theme.spacing(2)};
  margin-top: -2px;
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 32px;
  height: 32px;

  vertical-align: middle;
  display: inline;
`;

interface AppBarType {
  handleClick: (section: string) => void;
}

const AppBarComponent: React.FC<AppBarType> = ({ handleClick }) => {
  const router = useLocation();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [textColor, setTextColor] = useState("white");
  const [bgColor, setBgColor] = useState<PropTypes.Color | "transparent">(
    "transparent"
  );

  // Close mobile menu when navigation occurs
  useEffect(() => {
    setMobileOpen(false);
  }, [router.pathname]);
  const drawerWidth = 258;
  // const theme = useTheme();
  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
      if (position > 100) {
        setTextColor("inherit");
        setBgColor("inherit");
      } else {
        setTextColor("white");
        setBgColor("transparent");
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleButtonClick = (section: any) => {
    handleClick(section);
  };

  return (
    <AppBar
      position="sticky"
      color={bgColor}
      elevation={0}
      className="header-text"
    >
      <Toolbar sx={{ padding: 0 }}>
        <Container sx={{ padding: "0 !important" }}>
          <Grid container alignItems="center">
            <Grid item>
              <Brand>
                {scrollPosition > 100 ? <BrandIcon2 /> : <BrandIcon />}
              </Brand>
            </Grid>
            <Grid item>
              <Box sx={{ display: { xs: "none", md: "inline-block" } }}>
                {/* <Button mx={5} sx={{ color: textColor }}>
                  <Typography variant="subtitle1">About us</Typography>
                </Button> */}
                {/* <Button mx={5} sx={{ color: textColor }}>
                  <Typography variant="subtitle1">Product</Typography>
                </Button> */}
                <Button
                  mx={5}
                  onClick={() => handleButtonClick("SOLUTION")}
                  sx={{ color: textColor }}
                >
                  <Typography variant="subtitle1">Products</Typography>
                </Button>
                <Button
                  mx={5}
                  onClick={() => handleButtonClick("SOLUTION")}
                  sx={{ color: textColor }}
                >
                  <Typography variant="subtitle1">Solutions</Typography>
                </Button>
              </Box>
            </Grid>
            <Box sx={{ display: { xs: "none", md: "flex" }, marginLeft: "auto" }}>
              <Button
                sx={{ display: { xs: "none", md: "flex" } }}
                ml={2}
                color="primary"
                variant="contained"
                onClick={() => handleButtonClick("CONTACTUS")}
              >
                <Typography variant="subtitle1">Book a demo</Typography>
                <KeyboardArrowRight></KeyboardArrowRight>
              </Button>
            </Box>
            <Grid item sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                size="large"
                onClick={handleDrawerToggle}
              >
                <MenuIcon sx={{ color: textColor }} />
              </IconButton>
              <Box sx={{ display: { xs: "block", lg: "none" } }}>
                <Sidebar
                  PaperProps={{ style: { width: drawerWidth } }}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  items={dashboardItems}
                />
              </Box>
              {/* <Box sx={{ display: { xs: "none", md: "block" } }}>
                <Sidebar
                  PaperProps={{ style: { width: drawerWidth } }}
                  items={dashboardItems}
                />
              </Box> */}
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default AppBarComponent;
