import React from "react";

import { Box, Grid, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { Paper } from "@mui/material";
import styled from "@emotion/styled";
import "./my-custom-indicator.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@emotion/react";

const IndicatorIcon = styled.div`
  width: 10px;
  height: 15px;
  border-radius: 40%;
  opacity: 0.4;
  background: rgba(255, 255, 255, 0.5);
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
  position: relative;
  image-rendering: optimizequality;
  image-rendering: -webkit-optimize-contrast;
  ${(props) => props.theme.breakpoints.up("md")} {
  }
`;

function Item(props: any) {
  return (
    <Paper
      sx={{
        background: "transparent",
        height: "100%",
        width: "100%",
        boxShadow: "none",
      }}
    >
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <Grid item xs={12} md={6} lg={6}>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid item></Grid>
            <Grid item sx={{ maxWidth: "600px", width: "100%" }}>
              <Box pl={4}>
                <Typography variant="h1">{props.item.name}</Typography>
                <Typography variant="h5" mt={6}>
                  {props.item.title}
                </Typography>
                <Typography
                  variant="h6"
                  mt={6}
                  dangerouslySetInnerHTML={{ __html: props.item.description }}
                ></Typography>
                {/* <Button
                  sx={{
                    marginTop: "24px",
                  }}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Book a Demo <KeyboardArrowRight></KeyboardArrowRight>
                </Button> */}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          alignContent={"center"}
          alignItems={"center"}
          alignSelf={"center"}
          sx={{ paddingTop: "56px", display: { xs: "none", md: "flex" } }}
        >
          <Image src={props.item.image} />
        </Grid>
      </Grid>
    </Paper>
  );
}

function Hero() {
  var items = [
    {
      name: "qWorks.ai",
      title:
        "A Gen AI-based platform for Consumption / Subscription Economics for B2B Commerce.",
      description: "",
      image: "/static/img/landingPage/landing page-05.png",
    },
    // {
    //   name: "qUbe",
    //   title: "Unified Business Ecosystem",
    //   description:
    //     "Empower your enterprise with qUbe - the nexus of intelligent automation and strategic pricing. <br>A central platform that simplifies, integrates, and accelerates business processes, enabling organizations to navigate complex operations with ease and precision.",
    //   image: "/static/img/landingPage/homepage_hero_slider-bg-04.png",
    // },
    // {
    //   name: "qPilot",
    //   title: "Pioneering Precision with Generative AI",
    //   description:
    //     "Piloting Precision: Navigating the Future with Generative AI <br> qPilot is the generative AI companion that propels businesses forward, simplifying complex decisions with ease and precision.",
    //   image: "/static/img/landingPage/homepage_hero_slider-bg-05.png",
    // },
    // {
    //   name: "qSmart",
    //   title: "Mastering Subscription Excellence",
    //   description:
    //     "Navigate the Future of Subscription Management with qSmart <br> With qSmart, redefine the subscription experience. Automate, manage, and enhance every aspect of your subscription model, ensuring seamless customer journeys from sign-up to renewal.",
    //   image: "/static/img/landingPage/homepage_hero_slider-bg-02.png",
    // },
    // {
    //   name: "qFlex",
    //   title: "Fine-Tuning the Future of Consumption Economics",
    //   description:
    //     "Revolutionizing B2B Commerce with AI-Driven Consumption Economics <br> qFlex transforms commerce by harnessing AI to master usage-based pricing, optimize cost strategies, and ignite growth with actionable, data-driven economics.",
    //   image: "/static/img/landingPage/homepage_hero_slider-bg-03.png",
    // },
  ];
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLgScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isXlScreen = useMediaQuery(theme.breakpoints.down("xl"));
  return (
    <Box
      sx={{
        position: "absolute",
        background: "transparent",
        top: "80px",
        height: "760px",
        width: "100%",
        maxHeight: "760px",
      }}
    >
      <Carousel
        autoPlay={true}
        duration={400}
        interval={2000}
        indicators={isSmallScreen ? false : true}
        stopAutoPlayOnHover={true}
        sx={{ height: "100%" }}
        className=""
        navButtonsAlwaysInvisible
        IndicatorIcon={<IndicatorIcon />} // Previous Example
        indicatorIconButtonProps={{
          style: {
            padding: "10px", // 1
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {},
          className: "my-custom-indicator",
        }}
        indicatorContainerProps={{
          style: {
            marginTop: "-435px", // 5
            paddingLeft: isLgScreen ? 0 : isXlScreen ? "10px" : "225px",
            textAlign: "left", // 4
            display: "table-caption",
          },
        }}
      >
        {items.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </Carousel>
    </Box>
  );
}

export default Hero;
