import { useState } from "react";
import styled from "@emotion/styled";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  Typography as MuiTypography,
  Snackbar,
  TextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import { KeyboardArrowRight } from "@mui/icons-material";
import axiosInstance from "../../../utils/axios";

const blackListedDomains = [
  "facebook",
  "google",
  "amazon",
  "netflix",
  "hotmail",
  "microsoft",
  "gmail",
  "yahoo"
];

function ContactUs() {
  const Typography = styled(MuiTypography)(spacing);
  const Wrapper = styled.div`
    ${spacing};
    position: relative;
    background: #ffffff;
  `;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const ChipIcon = styled.div`
    display: flex;
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: #ceceff;
    color: #5858e8;
    width: 40px;
    height: 40px;
  `;

  const [snackbarProps, setSnackbarProps] = useState({
    message: "",
    open: false,
  });

  const onSubmit = async (values: any) => {
    try {
      const payload = {
        ...values,
        leadSource: "website",
        tags: [],
      };
      // console.log("payload:: ", payload);

      const response = await axiosInstance.post(
        "https://dev.qworks.ai/crm/api/website",
        payload
      );
      const isSuccess = response?.status === 200 || response?.status === 201;
      setSnackbarProps({
        open: true,
        message: isSuccess
          ? "Form is submitted successfully."
          : "Something went wrong.",
      });
    } catch (error: any) { }
  };

  return (
    <Wrapper py={18}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarProps?.open}
        autoHideDuration={6000}
        onClose={() => setSnackbarProps({ message: "", open: false })}
      >
        <Alert
          onClose={() => setSnackbarProps({ message: "", open: false })}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarProps?.message}
        </Alert>
      </Snackbar>
      <Container>
        <Box>
          <Typography variant="subtitle2" color="textSecondary">
            CONTACT US
          </Typography>
          <Typography variant="h4" mt={4}>
            Let’s connect
          </Typography>
        </Box>

        <Grid
          container
          justifyContent="space-between"
          // alignItems={"center"}
          mt={2}
        >
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            xl={6}
          // sx={{ marginBottom: { xs: "64px", md: "0" } }}
          >
            <Box>
              <Typography
                variant="h6"
                color="textSecondary"
                mt={4}
                sx={{ fontWeight: 700 }}
              >
                qWorks.ai - The Pinnacle of Agile Business Orchestration
              </Typography>
              <br />
              <Typography variant="h6">
                Book your demo to experience how qWorks.ai revolutionizes your
                business landscape. Dive into a world where intelligent
                automation meets strategic economics, streamlining your
                operations, accelerating market entry, and transcending
                traditional limitations of business process management.
              </Typography>
              <br />
              <Typography variant="h6">
                Discover how our platform can cut down your operational
                complexities and time spent on analytics, propelling you towards
                a smarter, more efficient future.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={6} xl={6}>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                company: "",
                how: "",
                reason: "",
                submit: false,
              }}
              validationSchema={Yup.object().shape({
                firstName: Yup.string()
                  .max(255)
                  .required("First name is required"),
                lastName: Yup.string()
                  .max(255)
                  .required("Last name is required"),
                email: Yup.string()
                  .email("Must be a valid email")
                  .max(255)
                  .required("Email is required")
                  .test(
                    "is-not-blacklist",
                    "Cannot use email with the provided domain!",
                    (value) => {
                      if (value) {
                        const currentDomain = value.substring(
                          value.indexOf("@") + 1,
                          value.indexOf(".")
                        );
                        return !blackListedDomains.includes(currentDomain);
                      }
                    }
                  ),
                company: Yup.string().required("Company is required"),
                how: Yup.string(),
                reason: Yup.string(),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  onSubmit(values);
                } catch (error: any) {
                  const message = error.message || "Something went wrong";
                  setStatus({ success: false });
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Grid container>
                    <Grid xs={6}>
                      <Box>
                        <TextField
                          fullWidth
                          label="First name"
                          name="firstName"
                          value={values.firstName}
                          error={Boolean(touched.firstName && errors.firstName)}
                          helperText={touched.firstName && errors.firstName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Box>
                    </Grid>
                    <Grid xs={6} sx={{ pl: 2 }}>
                      <Box ml={"auto"}>
                        <TextField
                          fullWidth
                          label="Last name"
                          name="lastName"
                          value={values.lastName}
                          error={Boolean(touched.lastName && errors.lastName)}
                          helperText={touched.lastName && errors.lastName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Box mt={6}>
                    <TextField
                      fullWidth
                      label="Work Email"
                      name="email"
                      value={values.email}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Box>
                  <Box mt={6}>
                    <TextField
                      fullWidth
                      label="Company Name"
                      name="company"
                      value={values.company}
                      error={Boolean(touched.company && errors.company)}
                      helperText={touched.company && errors.company}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Box>
                  <Box mt={6}>
                    <TextField
                      fullWidth
                      label="How did you know about qWorks.ai?"
                      name="how"
                      value={values.how}
                      error={Boolean(touched.how && errors.how)}
                      helperText={touched.how && errors.how}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Box>
                  <Box mt={6}>
                    <TextField
                      fullWidth
                      label="What's your reason for reaching out?"
                      name="reason"
                      value={values.reason}
                      error={Boolean(touched.reason && errors.reason)}
                      helperText={touched.reason && errors.reason}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Box>
                  <Box mt={8}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                    >
                      Submit <KeyboardArrowRight></KeyboardArrowRight>
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
}

export default ContactUs;
