import React, { useRef } from "react";

import AppBar from "./AppBar";
import JoinUs from "./JoinUs";
import { Box } from "@mui/material";

import Hero from "./Hero";
import "./index.css";
import Footer from "../../../components/Footer";
import Solution from "./Solutions";
import ContactUs from "./ContactUs";
function Presentation() {
  // Create a ref to the element you want to scroll into view
  const solutionRef = useRef<HTMLDivElement>(null);
  const contactUsRef = useRef<HTMLDivElement>(null);

  // Function to handle button click and scroll into view
  const handleClick = (section: string) => {
    // Check if the ref is not null before accessing its current property
    if (section === "SOLUTION" && solutionRef.current !== null) {
      // Scroll the element into view
      solutionRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (section === "CONTACTUS" && contactUsRef.current !== null) {
      // Scroll the element into view
      contactUsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <React.Fragment>
      <AppBar handleClick={(section) => handleClick(section)} />
      <Box
        sx={{
          width: "100%",
          position: "relative",
          height: "840px",
          top: "-65px"
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "840px",
          }}
        >
          <video
            className="video-tag"
            autoPlay
            loop
            muted
            width={"100%"}
            height={"100%"}
          >
            <source
              src="/static/img/landingPage/homepage-hero-bg-1.mp4"
              type="video/mp4"
            />
          </video>
        </Box>
        <Hero></Hero>
      </Box>
      <div ref={solutionRef}>
        <Solution handleClick={(section) => handleClick(section)}></Solution>
      </div>
      <div ref={contactUsRef}>
        <ContactUs></ContactUs>
      </div>
      <JoinUs></JoinUs>
      <Footer />
    </React.Fragment>
  );
}

export default Presentation;
